import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import RealTime from '../views/RealTime.vue'
import Trips from '../views/Trips.vue'
import Trip from '../views/Trip.vue'
import OverCapacity from '../views/OverCapacity.vue'
import Incidents from '../views/Incidents.vue'
import Companies from '../views/Companies.vue'
import Routes from '../views/Routes.vue'
import Drivers from '../views/Drivers.vue'
import Bus from '../views/Bus.vue'
import Employees from '../views/Employees.vue'
import Users from '../views/Users.vue'
import RouteDetails from '../views/RouteDetails.vue'
import Groups from '../views/Groups/Groups.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Stops from '../views/Stops.vue'
import EmployeeReport from '../views/EmployeeReport/Index.vue';
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Home,
    meta: {
      requieresAuth: true,
      roles: ['admin', 'company', 'guest', 'driver', 'user']
    }
  },
  {
    path: '/inicio',
    name: 'inicio',
    component: Home,
    meta: {
      requieresAuth: true,
      roles: ['admin', 'company', 'guest', 'driver', 'user']
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/viajes',
    name: 'viajes',
    component: Trips,
    meta: {
      requieresAuth: true,
      roles: ['admin', 'company', 'guest', 'driver', 'user']
    }
  },
  {
    path: '/tiempo-real',
    name: 'tiempo-real',
    component: RealTime,
    meta: {
      requieresAuth: true,
      roles: ['admin', 'company', 'guest', 'driver', 'user']
    }
  },
  {
    path: '/viaje/:id',
    name: 'viaje',
    component: Trip,
    meta: {
      requieresAuth: true,
      roles: ['admin', 'company', 'guest', 'driver', 'user']
    }
  },
  {
    path: '/empresas',
    name: 'empresas',
    component: Companies,
    meta: {
      requieresAuth: true,
      roles: ['admin']
    }
  },
  {
    path: '/reporte/sobrecupo',
    name: 'sobrecupo',
    component: OverCapacity,
    meta: {
      requieresAuth: true,
      roles: ['admin']
    }
  },
  {
    path: '/reporte/incidentes',
    name: 'incidentes',
    component: Incidents,
    meta: {
      requieresAuth: true,
      roles: ['admin']
    }
  },
  {
    path: '/rutas',
    name: 'rutas',
    component: Routes,
    meta: {
      requieresAuth: true,
      roles: ['admin']
    }
  },
  {
    path: '/grupos',
    name: 'grupos',
    component: Groups,
    meta: {
      requieresAuth: true,
      roles: ['admin']
    }
  },
  {
    path: '/ruta/:id',
    name: 'ruta',
    component: RouteDetails,
    meta: {
      requieresAuth: true,
      roles: ['admin']
    }
  },
  {
    path: '/paradas',
    name: 'paradas',
    component: Stops,
    meta: {
      requieresAuth: true,
      roles: ['admin']
    }
  },
  {
    path: '/unidades',
    name: 'unidades',
    component: Bus,
    meta: {
      requieresAuth: true,
      roles: ['admin']
    }
  },
  {
    path: '/choferes',
    name: 'choferes',
    component: Drivers,
    meta: {
      requieresAuth: true,
      roles: ['admin']
    }
  },
  {
    path: '/empleados',
    name: 'empleados',
    component: Employees,
    meta: {
      requieresAuth: true,
      roles: ['admin', 'company']
    }
  },
  {
    path: '/reporte/empleados',
    name: 'reporte-empleados',
    component: EmployeeReport,
    meta: {
      requieresAuth: true,
      roles: ['admin', 'guest', 'company']
    }
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: Users,
    meta: {
      requieresAuth: true,
      roles: ['admin']
    }
  },
  { path: '*', component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requieresAuth = to.matched.some(record => record.meta.requieresAuth)
  const roles = to.meta.roles
  let hasRole = false
  if (requieresAuth) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.state.accessToken === null) {
      next({ name: 'login' })
    } else {
      roles.forEach(role => {
        if (store.state.roles.indexOf(role) >= 0) {
          hasRole = true
        }
      })
      if (hasRole) {
        next() // go to wherever I'm going
      } else {
        next({ name: 'inicio' })
      }
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
