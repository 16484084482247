<template>
  <v-container fluid style="padding: 20px;">
    <v-row
          justify="center"
        >
      <v-col>
        <v-card style="padding: 0px 16px 0px 16px; margin-bottom: 10px;">
            <v-card-title>
                <v-row>
                  <v-col cols="12" sm="6" md="2">
                    Grupos
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-select
                      :items="groupsSelect"
                      label="Grupos"
                      dense
                      outlined
                      v-on:change="change"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-btn
                      :color=" edit ? 'danger' : 'primary' "
                      dark
                      class="mb-2"
                      v-on:click="editGroup"
                    >
                      {{ edit ? 'Cancelar' : 'Editar' }}
                      <v-icon
                        right
                        dark
                      >
                        {{ edit ? 'mdi-close' : 'mdi-pencil' }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      :color=" adding ? 'danger' : 'primary' "
                      dark
                      class="mb-2"
                      v-on:click="adding = !adding"
                    >
                      {{ adding ? 'Cancelar' : 'Agregar' }}
                      <v-icon
                        right
                        dark
                      >
                        {{ adding ? 'mdi-close' : 'mdi-plus-thick' }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
            </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="adding"
      justify="center"
    >
      <v-col>
        <v-card style="padding: 0px 16px 0px 16px; margin-bottom: 10px;">
          <v-card-title>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    Agregar Grupo
                  </v-col>
                </v-row>
          </v-card-title>
          <group action="add" :group="group"/>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="edit"
      justify="center"
    >
      <v-col>
        <v-card style="padding: 0px 16px 0px 16px; margin-bottom: 10px;">
          <v-card-title>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    Editar Grupo
                  </v-col>
                </v-row>
          </v-card-title>
          <group action="edit" :group="group"/>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="edit"
      justify="center"
    >
      <v-col>
        <v-card style="padding: 0px 16px 0px 16px; margin-bottom: 10px;">
          <v-card-title>
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    Rutas en el grupo
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <group-dialog :group="group" v-on:change="$refs.groupRoutesTable.fetchRoutes()"/>
                  </v-col>
                </v-row>
          </v-card-title>
          <group-routes ref="groupRoutesTable" :groupId="group.id"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import Group from './Group.vue'
import GroupRoutes from './GroupRoutes.vue'
import GroupDialog from './GroupDialog.vue'

export default {
  name: 'groups',
  data () {
    return {
      groups: [],
      groupsSelect: [],
      group: {
        id: 0,
        name: '',
        companyId: 0
      },
      adding: false,
      edit: false
    }
  },
  mounted () {
    this.fetchGroups()
  },
  methods: {
    fetchGroups: function () {
      this.$axios.get(`${this.$store.state.url}api/groups?token=${this.$store.state.accessToken}`)
        .then(response => {
          this.groups = response.data
          this.groupsSelect = this.groups.map(item => {
            return { text: item.name, value: item.id }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    change: function (group) {
      this.group = this.groups.find(item => item.id === group)
      this.edit = false
    },
    editGroup: function () {
      if (this.group.id > 0) {
        this.edit = !this.edit
      }
    }
  },
  components: {
    Group,
    GroupRoutes,
    GroupDialog
  }
}
</script>
