<template>
   <v-container height="100%" class="bg-container" style="background-image: url(https://www.transporterobledo.com/img/robledo-arco-leon.jpg;">
      <v-row>
        <v-col>
          <v-img
          lazy-src="@/assets/logo2.png"
          max-height="120"
          max-width="80"
          src="@/assets/logo2.png"
        ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="4"
          offset-md="4"
          class="pl-5 pr-5"
        >
          <v-card
          elevation="4"
          height="auto"
          class="pt-2 pb-2 pl-5 pr-5"
          style="border-radius:15px; position: relative; display: block;"
        >   <v-card-text>
              <v-container class="text-center">
                <div class="text-center">
                  <img
                      src="@/assets/logo.png"
                      width="85"
                    />
                </div>
                <v-row>
                  <v-col class="text-center">
                    <h2>Iniciar Sesión</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="email"
                      autofocus
                      label="Usuario"
                      @keyup.enter="login"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="password"
                      label="Contraseña"
                      type="password"
                      @keyup.enter="login"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="text-center">
                      <div class="mt-10">
                        <v-btn
                          rounded
                          color="primary"
                          dark
                          large
                          @click="login"
                          width="100%"
                        >
                          Iniciar
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="position: fixed; bottom: 1px; width:100%;">
      <v-col
        cols="12"
        sm="12"
        md="6">
        <v-alert
          border="bottom"
          elevation="4"
          type="warning"
          :value="alert"

        >
          Usuario o contraseña incorrectas
        </v-alert>
      </v-col>
    </v-row>
    </v-container>
</template>
<script>
export default {
  data () {
    return {
      email: '',
      password: '',
      error: '',
      alert: false
    }
  },
  created () {
    this.isLogged()
  },
  methods: {
    async login () {
      const data = {
        email: this.email,
        password: this.password
      }
      this.$axios.post(this.$store.state.url + 'api/login', data)
        .then(async res => {
          const data = res.data;
          if (data.status === 'success') {
            await this.$store.commit('setToken', res.data.access_token)
            this.getRoles(res.data.access_token)
          } else {
            this.alert = true
            this.alertCreated()
          }
        })
        .catch(err => {
          this.alert = true
          this.alertCreated()
          console.log(err)
        })
    },
    getRoles (token) {
      this.$axios.get(this.$store.state.url + 'api/user/roles?token=' + token)
        .then(async response => {
          await this.$store.commit('setRoles', response.data.data)
          this.$router.push({ name: 'inicio' })
        }).catch(err => {
          console.log(err)
        })
    },
    alertCreated () {
      setTimeout(() => {
        this.alert = false
      }, 2000)
    },
    async isLogged () {
      if (this.$store.state.accessToken != null) {
        this.$router.push({ name: 'inicio' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
h2 {
  color: #10348f;
  font-size: 1.5rem;
}
.text-center {
  text-align: center;
}
.bg-container {

  max-width: 100% !important;
  height: 100vh;
  padding: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.bg-container::before {
  content: "";
    display: block;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(93,84,240,0.5);
    background: -webkit-linear-gradient(to left, rgba(0,168,255,0.5), rgba(4, 0, 255,0.5));
    background: -o-linear-gradient(to left, rgba(0,168,255,0.5), rgba(4, 0, 255,0.5));
    background: -moz-linear-gradient(to left, rgba(0,168,255,0.5), rgba(4, 0, 255,0.5));
    background: linear-gradient(to left, rgba(0,168,255,0.5), rgba(4, 0, 255,0.5));
    pointer-events: none;
}
</style>
