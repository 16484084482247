<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col>
        <v-card elevation="3" style="padding: 10px">
          <v-row justify="center">
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="employeeBadge"
                append-icon="mdi-magnify"
                label="Número de nómina"
                single-line
                hide-details
                style="padding-top: 0px"
                @keyup.enter="get"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-btn color="primary" dark class="mb-2" @click="get">
                Buscar
                <v-icon right dark> mdi-account-search </v-icon>
              </v-btn>
            </v-col>
            <v-col v-if="employee" cols="12" sm="6" md="3">
              <v-btn color="red" dark class="mb-2 ml-2" @click="clean">
                Limpiar
                <v-icon right dark> mdi-delete </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="employee">
      <v-col cols="12" sm="12" md="12">
        <v-card style="padding: 20px">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <h4>Nombre:</h4>
              <h3 style="color: #444">{{ employee.name }}</h3>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <h4>Estatus:</h4>
              <h3 class="{employee.status ? text-green : text-red}">
                {{ employee.status ? "Activo" : "Inactivo" }}
              </h3>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <h4>Teléfono:</h4>
              <h3 style="color: #444">{{ employee.phone }}</h3>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <h4>Ruta asignada:</h4>
              <h3 style="color: #444">
                {{ assignedGroup ? assignedGroup.name : "Sin ruta asignada" }}
              </h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <h3>Historial de traslados</h3>
              </v-col>
              <v-col style="text-align: end">
                <v-btn
                  v-if="boards.length && !allBoards.length"
                  color="success"
                  dark
                  @click="getAllBoards"
                  :disabled="loadingAllBoards"
                >
                  <v-icon v-if="!loadingAllBoards" left> mdi-table </v-icon>
                  <v-progress-circular
                    v-else
                    indeterminate
                    size="14"
                  ></v-progress-circular>
                  {{ loadingAllBoards ? "Creando tu Excel" : "Generar Excel" }}
                </v-btn>
                <download-excel
                  v-if="allBoards.length"
                  class="btn btn-success"
                  :data="allBoards"
                  :fields="excelHeaders"
                  worksheet="Paraderos"
                  :name="excelName"
                >
                  <v-btn tile color="success">
                    <v-icon left> mdi-table </v-icon>
                    Descargar Excel
                  </v-btn>
                </download-excel>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="boards"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              hide-default-footer
              class="elevation-1"
            >
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ml-2" style="position: fixed; bottom: 1px; width: 100%">
      <v-col cols="12" sm="12" md="6">
        <v-alert border="bottom" elevation="4" type="red" :value="alert">
          {{ message }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    employeeBadge: "",
    employee: null,
    assignedGroup: null,
    allBoards: [],
    loadingAllBoards: false,
    boards: [],
    headers: [
      {
        text: "Nombre Ruta",
        align: "start",
        sortable: false,
        value: "route",
      },
      { text: "Entrada / Salida", value: "type" },
      { text: "Hora de abordo", value: "boarding_time" },
      { text: "Paradero", value: "stop" },
      { text: "Conductor", value: "driver" },
      { text: "Unidad", value: "bus" },
    ],
    excelHeaders: {
      "Nombre Ruta": "route",
      "Entrada / Salida": "type",
      "Hora de abordo": "boarding_time",
      Paradero: "stop",
      Conductor: "driver",
      Unidad: "bus",
    },
    excelName: "name.xls",
    page: 0,
    itemsPerPage: 0,
    pageCount: 0,
    alert: false,
    message: "",
  }),

  watch: {
    page(val) {
      this.getBoards(val);
    },
  },

  created() {},

  methods: {
    get() {
      this.getEmployeeInfo();
      this.getBoards();
    },
    getEmployeeInfo() {
      this.$axios
        .get(
          this.$store.state.url +
            "api/employees/" +
            this.employeeBadge +
            "?token=" +
            this.$store.state.accessToken
        )
        .then((response) => {
          const data = response.data;
          if (!data) {
            this.alert = true;
            this.message = "No se encontró el empleado";
            this.dismissAlert();
            return;
          }
          this.employee = data.employee;
          this.assignedGroup = data.assignedGroup;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBoards(currentPage = 1) {
      this.$axios
        .get(
          this.$store.state.url +
            "api/employees/boards/" +
            this.employeeBadge +
            "?page=" +
            currentPage +
            "&token=" +
            this.$store.state.accessToken
        )
        .then((response) => {
          const data = response.data;
          this.boards = data.data;
          this.page = data.current_page;
          this.pageCount = data.last_page;
          this.itemsPerPage = data.per_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllBoards() {
      this.excelName = this.employeeBadge + " - Historial" + ".xls";
      this.loadingAllBoards = true;
      this.$axios
        .get(
          this.$store.state.url +
            "api/employees/boards/all/" +
            this.employeeBadge +
            "?token=" +
            this.$store.state.accessToken
        )
        .then((response) => {
          this.allBoards = response.data;
          this.loadingAllBoards = false;
        })
        .catch((err) => {
          console.log(err);
          this.allBoards = [];
          this.loadingAllBoards = false;
        });
    },
    clean() {
      this.employee = null;
      this.employeeBadge = "";
      this.boards = [];
      this.allBoards = [];
      this.excelName = "name.xls";
      this.page = 0;
      this.pageCount = 0;
      this.itemsPerPage = 0;
    },
    dismissAlert() {
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    },
  },
};
</script>
<style>
.text-green {
  color: rgb(17, 153, 17);
}
.text-red {
  color: rgb(161, 5, 5);
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: darkgrey !important;
  color: white !important;
}
.theme--dark.v-btn.v-btn--disabled .v-icon {
  color: white !important;
}
</style>
