<template>
  <v-row>
    <v-col cols="3">
      <v-select
        :items="companies"
        v-model="data.company_id"
        label="Empresa"
        dense
        outlined
      ></v-select>
    </v-col>
    <v-col cols="5">
      <v-text-field
            label="Nombre del grupo"
            v-model="data.name"
            outlined
            dense
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        v-on:click="action === 'add' ? save() : update()"
      >
        Guardar
        <v-icon
          right
          dark
        >
          mdi-plus-thick
        </v-icon>
      </v-btn>
      <v-btn
        v-if="action !== 'add'"
        color="red"
        dark
        class="ml-2 mb-2"
        v-on:click="deleteGroup"
      >
        Eliminar
        <v-icon
          right
          dark
        >
          mdi-delete
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'group',
  props: {
    action: {
      type: String,
      default: 'add'
    },
    group: {
      default: {
        id: 0,
        name: '',
        company_id: 0
      }
    }
  },
  data () {
    return {
      companies: [],
      data: {
        id: 0,
        name: '',
        company_id: 1
      }
    }
  },
  mounted () {
    this.fetchCompanies()
    if (this.action === 'edit') {
      this.data = this.group
    }
  },
  methods: {
    fetchCompanies: function () {
      this.$axios.get(`${this.$store.state.url}api/companies?token=${this.$store.state.accessToken}`)
        .then(response => {
          this.companies = response.data.map(item => {
            return { text: item.name, value: item.id }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    save: function () {
      if (this.data.company_id > 0 && this.data.name.length > 0) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.$store.state.accessToken}`
          }
        }
        this.$axios.post(`${this.$store.state.url}api/groups`, this.data, config)
          .then(response => {
            console.log(response)
          }).catch(err => {
            console.log(err)
          })
      } else {
        console.log('eeee')
      }
    },
    update: function () {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.put(`${this.$store.state.url}api/groups`, this.group, config)
        .then(response => {
          console.log(response)
        }).catch(err => {
          console.log(err)
        })
    },
    deleteGroup: function () {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.delete(`${this.$store.state.url}api/groups/${this.data.id}`, config)
        .then(response => {
          console.log(response)
        }).catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
