<script>
import { Line } from 'vue-chartjs'
import moment from 'moment'
export default {
  name: 'incidents_chart',
  extends: Line,
  data () {
    return {
      datacollection: {
        labels: [],
        datasets: [
          {
            label: 'Incidentes',
            backgroundColor: '#38a969',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            data: []
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  methods: {
    fetchIncidentsInfo: function () {
      moment.lang('es')
      this.$axios.get(this.$store.state.url + 'api/charts/incidents?token=' + this.$store.state.accessToken)
        .then(async response => {
          await response.data.forEach(item => {
            this.datacollection.labels.push(moment(item.day).format('ddd DD/M'))
            this.datacollection.datasets[0].data.push(item.values)
          })
          this.renderChart(this.datacollection, this.options)
        }).catch(err => {
          console.log(err)
        })
    }
  },
  mounted () {
    this.fetchIncidentsInfo()
  }
}
</script>
