<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col style="padding: 30px;">
        <v-card
          elevation="3"
        >
          <v-data-table
            :headers="headers"
            :items="users"
            sort-by="name"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Usuarios</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="dialog"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Nuevo Usuario
                      <v-icon
                        right
                        dark
                      >
                        mdi-plus-thick
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="8"
                          >
                            <v-text-field
                              v-model="editedItem.email"
                              label="Correo*"

                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="8"
                          >
                            <v-text-field
                              v-model="editedItem.name"
                              label="Nombre*"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="8"
                          >
                            <v-text-field
                              v-model="editedItem.password"
                              label="Contraseña*"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                          >
                            <v-select
                              :items="roles"
                              v-model="editedItem.roleId"
                              label="Tipo de usuario"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col
                            class="d-flex"
                            cols="4"
                            sm="4"
                          >
                            <v-select v-if="editedItem.roleId === 2 || editedItem.roleId === 4"
                            :items="companies"
                            v-model="editedItem.companyId"
                            label="Empresa"
                            dense
                            outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">¿Deseas eliminar esta Usuario?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initialize"
              >
                Reset
              </v-btn>
            </template>
            <template v-slot:item.name="{ item }">
              <span style="font-weight: 600;">{{item.name}}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="position: fixed; bottom: 1px; width:100%;">
      <v-col
        cols="12"
        sm="12"
        md="6">
        <v-alert
          border="bottom"
          elevation="4"
          type="success"
          :value="alert"

        >
          Guardado correctamente.
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    alert: false,
    headers: [
      {
        text: 'Nombre',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Correo', value: 'email' },
      { text: 'Tipo de usuario', value: 'role' },
      { text: 'Acciones', value: 'actions', sortable: false }
    ],
    users: [],
    roles: [],
    companies: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      email: '',
      name: '',
      password: '',
      roleId: 0,
      companyId: 0
    },
    defaultItem: {
      id: 0,
      email: '',
      name: '',
      password: '',
      roleId: 0,
      companyId: 0
    }
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario'
    },
    formRole () {
      return this.editedIndex === -1
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  created () {
    this.initialize()
    this.getRoles()
    this.fetchCompanies()
  },

  methods: {
    initialize: function () {
      this.$axios.get(this.$store.state.url + 'api/users?token=' + this.$store.state.accessToken)
        .then(response => {
          this.users = response.data
        }).catch(err => {
          console.log(err)
        })
    },
    getRoles: function () {
      this.$axios.get(this.$store.state.url + 'api/roles?token=' + this.$store.state.accessToken)
        .then(response => {
          const data = response.data
          for (var i = 0; i < data.length; i++) {
            this.roles.push(
              { text: data[i].description, value: data[i].id }
            )
          }
        }).catch(err => {
          console.log(err)
        })
    },
    fetchCompanies: function () {
      this.$axios.get(this.$store.state.url + 'api/companies?token=' + this.$store.state.accessToken)
        .then(response => {
          const data = response.data
          for (var i = 0; i < data.length; i++) {
            this.companies.push(
              { text: data[i].alias_name, value: data[i].id }
            )
          }
        }).catch(err => {
          console.log(err)
        })
    },
    editItem (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.delete(this.users[this.editedIndex])
      this.users.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        this.update(this.editedItem)
        Object.assign(this.users[this.editedIndex], this.editedItem)
      } else {
        this.savePost(this.editedItem)
        this.users.push(this.editedItem)
      }
      this.close()
    },
    savePost (item) {
      const data = {
        name: item.name,
        email: item.email,
        password: item.password,
        roleId: item.roleId,
        companyId: item.companyId
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/users/save', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
          this.initialize()
        }).catch(err => {
          console.log(err)
        })
    },
    update (item) {
      const data = {
        id: item.id,
        name: item.name,
        email: item.email,
        password: item.password,
        roleId: item.roleId,
        companyId: item.companyId
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/users/update', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
        }).catch(err => {
          console.log(err)
        })
    },
    delete (item) {
      const data = {
        id: item.id
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/users/delete', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
        }).catch(err => {
          console.log(err)
        })
    },
    alertCreated () {
      setTimeout(() => {
        this.alert = false
      }, 2000)
    },
    getColor (disbaledAt) {
      if (disbaledAt == null) return 'green'
      else return 'red'
    }
  }
}
</script>
