<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col style="padding: 30px;">
        <v-card
          elevation="3"
          style="padding: 10px;"
        >
          <v-row>
            <v-col
              v-if="this.$store.state.roles.includes('admin')"
              cols="12"
              sm="12"
              md="4"
            >
              <v-select
                :items="companies"
                v-model="searchCompany"
                label="Empresa"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col
              v-else
              cols="2"
            ></v-col>
            <v-col
              cols="12"
              sm="1"
              md="1">

            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
            >
              <v-text-field
                v-model="searchBadge"
                append-icon="mdi-magnify"
                label="No de nómina"
                single-line
                hide-details
                style="padding-top:0px;"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="getEmployees"
              >
                Buscar
                <v-icon
                  right
                  dark
                >
                  mdi-account-search
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col style="padding: 30px;">
        <v-card
          elevation="3"
        >
          <v-data-table
            :headers="headers"
            :items="employees"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Empleados</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <h3>{{from + ' - ' + to + '  | Totales ' +  total}}</h3>
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="dialogAdd"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Agregar Empleados
                      <v-icon
                        right
                        dark
                      >
                        mdi-plus-thick
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Agregar Empleados</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                          >
                            <v-select
                              :items="companies"
                              v-model="editedItem.company_id"
                              label="Empresa"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-select
                              :items="groups"
                              v-model="editedItem.group_id"
                              label="Grupo asignado"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="8"
                          >
                            <v-file-input
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              label="Subir archivo"
                              id="excelFile"
                            ></v-file-input>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeAdd"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="dialogDeleteMany"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      class="red mb-2"
                      v-bind="attrs"
                      v-on="on"
                      style="margin-left:10px;"
                    >
                      Eliminar Empleados
                      <v-icon
                        right
                        dark
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Eliminar Empleados</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                          >
                            <v-select
                              :items="companies"
                              v-model="editedItem.company_id"
                              label="Empresa"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="8"
                          >
                            <v-file-input
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              label="Subir archivo"
                              id="excelFileDelete"
                            ></v-file-input>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeDeleteMany"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteMany"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogUpdate" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">Editar Empleado</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                          >
                            <v-select
                              :items="companies"
                              v-model="editedItem.company_id"
                              label="Empresa"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="editedItem.company_badge"
                              append-icon="mdi-badge-account"
                              label="No de nómina"
                              single-line
                              style="padding-top:0px;"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="editedItem.name"
                              append-icon="mdi-account"
                              label="Nombre"
                              single-line
                              style="padding-top:0px;"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="editedItem.phone"
                              append-icon="mdi-phone"
                              label="Teléfono"
                              single-line
                              style="padding-top:0px;"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            <v-select
                              :items="groups"
                              v-model="editedItem.group_id"
                              label="Grupo"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeUpdate"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="update(editedItem)"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">¿Deseas eliminar este Empleado?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="printItem(item)"
              >
                mdi-printer
              </v-icon>
              <v-icon
                small
                class="mr-2"
                @click="updateItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initialize"
              >
                Reset
              </v-btn>
            </template>
            <template v-slot:item.internal_badge="{ item }">
              <span style="font-weight: 600;">{{item.internal_badge}}</span>
            </template>
            <template v-slot:item.created_at="{ item }">
              <span>{{ item.created_at | moment("DD/MM/YYYY")}}</span>
            </template>
            <template v-slot:item.disabled_at="{ item }">
              <v-chip
                :color="getColor(item.disabled_at)"
                dark
              >
              </v-chip>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="pageCount"
            ></v-pagination>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="position: fixed; bottom: 1px; width:100%;">
      <v-col
        cols="12"
        sm="12"
        md="6">
        <v-alert
          elevation="4"
          type="success"
          :value="alert"
        >
          Guardado correctamente.
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import xlsx from 'read-excel-file'
export default {
  data: () => ({
    dialogAdd: false,
    dialogUpdate: false,
    dialogDelete: false,
    dialogDeleteMany: false,
    alert: false,
    companies: [],
    groups: [],
    searchCompany: 0,
    searchBadge: '',
    employees: [],
    headers: [
      {
        text: 'Código',
        align: 'start',
        sortable: false,
        value: 'internal_badge'
      },
      { text: 'Empresa', value: 'company' },
      { text: 'No de nomina', value: 'company_badge' },
      { text: 'Nombre', value: 'name' },
      { text: 'Teléfono', value: 'phone' },
      { text: 'Fecha', value: 'created_at' },
      { text: 'Acciones', value: 'actions', sortable: false }
    ],
    page: 0,
    from: 0,
    to: 0,
    total: 0,
    itemsPerPage: 0,
    pageCount: 0,
    options: {},
    editedIndex: -1,
    editedItem: {
      company_id: 0,
      group_id: 0
    },
    defaultItem: {
      company_id: 0
    }
  }),

  watch: {
    dialogAdd (val) {
      val || this.closeAdd()
    },
    dialogUpdate (val) {
      val || this.closeUpdate()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogDeleteMany (val) {
      val || this.closeDeleteMany()
    },
    page (val) {
      this.getEmployees(val)
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    getEmployees: function (currentPage) {
      this.$axios.get(this.$store.state.url + 'api/employees?' +
        'search=' + this.searchBadge +
        '&company=' + this.searchCompany +
        '&page=' + currentPage +
        '&token=' + this.$store.state.accessToken
      )
        .then(response => {
          this.employees = response.data.data
          this.page = response.data.current_page
          this.itemsPerPage = response.data.per_page
          this.pageCount = response.data.last_page
          this.from = response.data.from
          this.to = response.data.to
          this.total = response.data.total
        }).catch(err => {
          console.log(err)
        })
    },
    getGroups: function () {
      this.$axios.get(this.$store.state.url + 'api/groups?token=' + this.$store.state.accessToken)
        .then(response => {
          const data = response.data
          for (var i = 0; i < data.length; i++) {
            this.groups.push(
              { text: data[i].name, value: data[i].id }
            )
          }
        }).catch(err => {
          console.log(err)
        })
    },
    initialize: function () {
      this.getEmployees(1)
      this.getGroups()
      this.$axios.get(this.$store.state.url + 'api/companies?token=' + this.$store.state.accessToken)
        .then(response => {
          const data = response.data
          for (var i = 0; i < data.length; i++) {
            this.companies.push(
              { text: data[i].alias_name, value: data[i].id }
            )
          }
        }).catch(err => {
          console.log(err)
        })
    },
    async getExcelFile (filename) {
      const input = document.getElementById(filename)
      var loadedEmployees = []
      await xlsx(input.files[0]).then(async (rows) => {
        await rows.forEach((row, i) => {
          if (i > 0) {
            const employee = {
              badgenumber: row[0],
              name: row[1] ?? 'NA',
              phone: row[2] ?? 'NA'
            }
            loadedEmployees.push(employee)
          }
        })
        console.log(loadedEmployees)
      })
      return loadedEmployees
    },
    addItem (item) {
      this.editedIndex = this.employees.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogAdd = true
    },

    printItem (item) {
      window.open(this.$store.state.url + 'api/qrcode/' + item.id +
      '?token=' + this.$store.state.accessToken, '_blank')
    },

    updateItem (item) {
      this.editedIndex = this.employees.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogUpdate = true
    },

    deleteItem (item) {
      this.editedIndex = this.employees.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.delete(this.employees[this.editedIndex])
      this.employees.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    closeAdd () {
      this.dialogAdd = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeUpdate () {
      this.dialogUpdate = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDeleteMany () {
      this.dialogDeleteMany = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      this.getExcelFile('excelFile').then(loadedEmployees => {
        if (loadedEmployees.length > 0) {
          this.savePost(loadedEmployees)
        }
        this.closeAdd()
      })
    },
    deleteMany () {
      this.getExcelFile('excelFileDelete').then(loadedEmployees => {
        if (loadedEmployees.length > 0) {
          this.deleteManyPost(loadedEmployees)
        }
        this.closeAdd()
      })
    },
    savePost (loadedEmployees) {
      const data = {
        companyId: this.editedItem.company_id,
        groupId: this.editedItem.group_id,
        employees: loadedEmployees
      }

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/employees/save', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
          window.open(this.$store.state.url + 'api/qrcode?token=' + this.$store.state.accessToken, '_blank')
          this.getEmployees(1)
        }).catch(err => {
          console.log(err)
        })
    },
    update (item) {
      const data = {
        id: item.id,
        name: item.name,
        phone: item.phone,
        badgenumber: item.company_badge,
        companyId: item.company_id,
        groupId: item.group_id
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/employees/update', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
          this.getEmployees(1)
        }).catch(err => {
          console.log(err)
        })
      this.closeUpdate()
    },
    delete (item) {
      const data = {
        id: item.id
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/employees/delete', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
        }).catch(err => {
          console.log(err)
        })
    },
    deleteManyPost (loadedEmployees) {
      const data = {
        companyId: this.editedItem.company_id,
        employees: loadedEmployees
      }
      this.$axios.post(this.$store.state.url + 'api/employees/deletemany', data)
        .then(response => {
          this.alert = true
          this.alertCreated()
        }).catch(err => {
          console.log(err)
        })
      this.closeDeleteMany()
    },
    alertCreated () {
      setTimeout(() => {
        this.alert = false
      }, 2000)
    },
    getColor (disbaledAt) {
      if (disbaledAt == null) return 'green'
      else return 'red'
    }
  }
}
</script>
