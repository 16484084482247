<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        sm="7"
      >
        <v-card
          elevation="3"
        >
          <v-data-table
            :headers="driverHeaders"
            :items="drivers"
            sort-by="calories"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Choferes</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="dialog"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Nuevo Chofer
                      <v-icon
                        right
                        dark
                      >
                        mdi-plus-thick
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="8"
                          >
                            <v-text-field
                              v-model="editedItem.email"
                              label="Correo"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="8"
                          >
                            <v-text-field
                              v-model="editedItem.name"
                              label="Nombre"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="8"
                          >
                            <v-text-field
                              v-model="editedItem.password"
                              label="Contraseña"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                          >
                            <v-select
                              :items="buses"
                              v-model="editedItem.busId"
                              label="Unidad"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">¿Deseas eliminar esta Chofer?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
               <v-icon
                small
                class="mr-2"
                @click="selectItem(item)"
              >
                mdi-bus
              </v-icon>
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initialize"
              >
                Reset
              </v-btn>
            </template>
            <template v-slot:item.name="{ item }">
              <span style="font-weight: 600;">{{item.name}}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="5"
        md="5"
      >
        <v-card
          elevation="3"
        >
          <v-data-table
            :headers="routeHeaders"
            :items="routes"
            sort-by="name"
            class="elevation-1"
            style="cursor: pointer"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Rutas</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                {{selectedItem.name}}
                <v-spacer></v-spacer>
                <v-dialog v-if="selectedIndex >= 0"
                  v-model="dialogRoute"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Agregar Ruta
                      <v-icon
                        right
                        dark
                      >
                        mdi-plus-thick
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Agregar Ruta</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="8"
                          >
                            <v-select
                              :items="routesList"
                              v-model="newRouteId"
                              label="Ruta"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeRoute"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="saveRoute"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogRouteDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">¿Deseas eliminar esta Ruta?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeRouteDelete">Cancelar</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteRouteConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                @click="deleteRoute(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              Sin Rutas
            </template>
            <template v-slot:item.name="{ item }">
              <span style="font-weight: 600;">{{item.name}}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="position: fixed; bottom: 1px; width:100%;">
      <v-col
        cols="12"
        sm="12"
        md="6">
        <v-alert
          border="bottom"
          elevation="4"
          type="success"
          :value="alert"

        >
          Guardado correctamente.
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogRoute: false,
    dialogRouteDelete: false,
    alert: false,
    driverHeaders: [
      {
        text: 'Nombre',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Correo', value: 'email' },
      { text: 'Unidad', value: 'bus' },
      { text: 'Acciones', value: 'actions', sortable: false }
    ],
    drivers: [],
    routeHeaders: [
      {
        text: 'Nombre',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Empresa', value: 'company' },
      { text: 'Acciones', value: 'actions', sortable: false }
    ],
    routes: [],
    routesList: [],
    newRouteId: 0,
    buses: [],
    selectedIndex: -1,
    selectedItem: {
      id: 0,
      driverId: 0,
      bus: '',
      email: '',
      name: '',
      busId: 0
    },
    editedIndex: -1,
    editedItem: {
      id: 0,
      email: '',
      name: '',
      password: '',
      busId: 0
    },
    editedRouteIndex: -1,
    editedRouteItem: {
      id: 0,
      name: '',
      company: ''
    },
    defaultItem: {
      email: '',
      name: '',
      password: '',
      busId: 0
    }
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Chofer' : 'Editar Chofer'
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  created () {
    this.initialize()
    this.getBuses()
    this.getRoutesList()
  },

  methods: {
    initialize: function () {
      this.$axios.get(this.$store.state.url + 'api/drivers?token=' + this.$store.state.accessToken)
        .then(response => {
          this.drivers = response.data
        }).catch(err => {
          console.log(err)
        })
    },
    getBuses: function () {
      this.$axios.get(this.$store.state.url + 'api/buses?token=' + this.$store.state.accessToken)
        .then(response => {
          const data = response.data
          for (var i = 0; i < data.length; i++) {
            this.buses.push(
              { text: data[i].bus, value: data[i].id }
            )
          }
        }).catch(err => {
          console.log(err)
        })
    },
    getRoutesList: function () {
      this.$axios.get(this.$store.state.url + 'api/routes?token=' + this.$store.state.accessToken)
        .then(response => {
          const data = response.data
          for (var i = 0; i < data.length; i++) {
            this.routesList.push(
              { text: data[i].name, value: data[i].id }
            )
          }
        }).catch(err => {
          console.log(err)
        })
    },
    getRoutes: function (id) {
      this.$axios.get(this.$store.state.url + 'api/routes/driver/' + id + '?token=' + this.$store.state.accessToken)
        .then(response => {
          this.routes = response.data.data.routes
        }).catch(err => {
          console.log(err)
        })
    },
    editItem (item) {
      this.editedIndex = this.drivers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    selectItem (item) {
      this.selectedIndex = this.drivers.indexOf(item)
      this.selectedItem = Object.assign({}, item)
      this.getRoutes(item.id)
    },

    deleteItem (item) {
      this.editedIndex = this.drivers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteRoute (item) {
      this.editedRouteIndex = this.routes.indexOf(item)
      this.editedRouteItem = Object.assign({}, item)
      this.dialogRouteDelete = true
    },

    deleteItemConfirm () {
      this.delete(this.drivers[this.editedIndex])
      this.drivers.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    deleteRouteConfirm () {
      this.deleteRoutePost(this.routes[this.editedRouteIndex])
      this.routes.splice(this.editedRouteIndex, 1)
      this.closeRouteDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeRoute () {
      this.dialogRoute = false
      this.$nextTick(() => {
        this.routeItem = Object.assign({}, this.defaultItem)
        this.routeIndex = -1
      })
    },

    closeRouteDelete () {
      this.dialogRouteDelete = false
      this.$nextTick(() => {
        this.routeItem = Object.assign({}, this.defaultItem)
        this.routeIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        this.update(this.editedItem)
        Object.assign(this.drivers[this.editedIndex], this.editedItem)
      } else {
        this.savePost(this.editedItem)
        this.drivers.push(this.editedItem)
      }
      this.close()
    },
    saveRoute () {
      this.saveRoutePost(this.editedRouteItem)
      this.routes.push(this.editedRouteItem)
      this.closeRoute()
    },
    savePost (item) {
      const data = {
        name: item.name,
        email: item.email,
        password: item.password,
        busId: item.busId
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/drivers/save', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
          this.initialize()
        }).catch(err => {
          console.log(err)
        })
    },
    saveRoutePost (item) {
      const data = {
        driverId: this.selectedItem.driverId,
        routeId: this.newRouteId
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/drivers/route/save', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
          this.getRoutes(this.selectedItem.id)
        }).catch(err => {
          console.log(err)
        })
    },
    update (item) {
      const data = {
        id: item.id,
        name: item.name,
        email: item.email,
        password: item.password,
        busId: item.busId
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/drivers/update', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
          this.initialize()
        }).catch(err => {
          console.log(err)
        })
    },
    delete (item) {
      const data = {
        id: item.id
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/drivers/delete', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
        }).catch(err => {
          console.log(err)
        })
    },

    deleteRoutePost (item) {
      const data = {
        id: item.id
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/drivers/route/delete', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
        }).catch(err => {
          console.log(err)
        })
    },
    alertCreated () {
      setTimeout(() => {
        this.alert = false
      }, 2000)
    },
    getColor (disbaledAt) {
      if (disbaledAt == null) return 'green'
      else return 'red'
    }
  }
}
</script>
