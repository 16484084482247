<template>
  <v-container fluid>
    <v-row >
      <v-col style="padding: 30px;"
        cols="12"
        sm="12"
        md="12"
      >
        <v-card
          elevation="3"
        >
          <v-data-table
            :headers="headers"
            :items="routes"
            sort-by="name"
            class="elevation-1"
            style="cursor: pointer"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Rutas</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="dialog"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Nueva Ruta
                      <v-icon
                        right
                        dark
                      >
                        mdi-plus-thick
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="8"
                          >
                            <v-text-field
                              v-model="editedItem.name"
                              label="Nombre"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-select
                              :items="companies"
                              v-model="editedItem.company_id"
                              label="Empresa"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">¿Deseas eliminar esta Ruta?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="goRoute(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="getRoutes"
              >
                Reset
              </v-btn>
            </template>
            <template v-slot:item.name="{ item }">
              <span style="font-weight: 600;">{{item.name}}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="position: fixed; bottom: 1px; width:100%;">
      <v-col
        cols="12"
        sm="12"
        md="6">
        <v-alert
          border="bottom"
          elevation="4"
          type="success"
          :value="alert"

        >
          Guardado correctamente.
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    alert: false,
    companies: [],
    headers: [
      {
        text: 'Nombre Ruta',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Empresa', value: 'company' },
      { text: 'Acciones', value: 'actions', sortable: false }
    ],
    routes: [],
    editedIndex: -1,
    editedItem: {
      company_id: 0,
      name: ''
    },
    defaultItem: {
      company_id: 0,
      name: ''
    }
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nueva Ruta' : 'Editar Ruta'
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  created () {
    this.getRoutes()
    this.getCompanies()
  },

  methods: {
    getRoutes: function () {
      this.$axios.get(this.$store.state.url + 'api/routes?token=' + this.$store.state.accessToken)
        .then(response => {
          this.routes = response.data
        }).catch(err => {
          console.log(err)
        })
    },
    getCompanies () {
      this.$axios.get(this.$store.state.url + 'api/companies?token=' + this.$store.state.accessToken)
        .then(response => {
          const data = response.data
          for (var i = 0; i < data.length; i++) {
            this.companies.push(
              { text: data[i].alias_name, value: data[i].id }
            )
          }
        }).catch(err => {
          console.log(err)
        })
    },
    editItem (item) {
      this.editedIndex = this.routes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.routes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.delete(this.routes[this.editedIndex])
      this.routes.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        this.update(this.editedItem)
        Object.assign(this.routes[this.editedIndex], this.editedItem)
      } else {
        this.savePost(this.editedItem)
        this.routes.push(this.editedItem)
      }
      this.close()
    },
    savePost (item) {
      const data = {
        company_id: item.company_id,
        name: item.name
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/routes/save', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
          this.getRoutes()
        }).catch(err => {
          console.log(err)
        })
    },
    update (item) {
      const data = {
        id: item.id,
        company_id: item.company_id,
        name: item.name
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/routes/update', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
        }).catch(err => {
          console.log(err)
        })
    },
    delete (item) {
      const data = {
        id: item.id
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/routes/delete', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
        }).catch(err => {
          console.log(err)
        })
    },
    alertCreated () {
      setTimeout(() => {
        this.alert = false
      }, 2000)
    },
    goRoute (route) {
      this.$router.push({ name: 'ruta', params: { id: route.id } })
    }
  }
}
</script>
