<template>
  <v-container fluid>
    <v-row
          justify="center"
        >
      <v-col style="padding: 30px;">
        <v-card style="padding: 0px 16px 0px 16px; margin-bottom: 10px;">
            <v-card-title>
                Filtrar:
            </v-card-title>
            <v-row>
              <v-col
                class="d-flex"
                cols="3"
                sm="3"
              >
                <v-select
                  :items="companies"
                  v-model="searchCompany"
                  label="Empresa"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col
                class="d-flex"
                cols="4"
                sm="4"
              >
                <v-select
                  :items="routes"
                  v-model="searchRoute"
                  label="Ruta"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col
                class="d-flex"
                cols="3"
                sm="3"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Elige una fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="padding-top: 0px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                class="d-flex"
                cols="2"
                sm="2"
              >
                <v-btn
                  dark
                  class="warning"
                  @click="clearFilter"
                >
                  Limpiar
                  <v-icon
                    right
                    dark
                  >
                    mdi-eraser
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex"
                cols="3"
                sm="3"
              >
                <v-select
                  :items="shifts"
                  v-model="searchShift"
                  label="Horario"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col
                class="d-flex"
                cols="3"
                sm="3"
              >
                <v-select
                  :items="types"
                  v-model="searchType"
                  label="Tipo"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
        </v-card>
        <v-card style="padding: 20px;">
          <v-card-title>
            <h3>Traslados</h3>
            <v-divider
                  class="mx-4"
                  inset
                  vertical
            ></v-divider>
            <h5>{{from + ' - ' + to + '  | Totales ' +  total}}</h5>
            <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  @keyup.enter="fetchTrips(1)"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
          </v-card-title>
              <v-data-table
                :headers="headers"
                :items="trips"
                :items-per-page="trips.length"
                class="elevation-1"
                @click:row="goTrip"
                style="cursor: pointer"
                hide-default-footer
              >
                <template v-slot:item.folio="{ item }">
                  <span style="font-weight: 600;">{{item.folio}}</span>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <span>{{ item.created_at | moment("DD/MM/YYYY LT")}}</span>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                ></v-pagination>
              </div>
            </v-card>
          </v-col>
        </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'viajes',
  methods: {
    fetchTrips: function (currentPage) {
      this.$axios.get(this.$store.state.url + 'api/trips?' +
      'page=' + currentPage +
      '&search=' + this.search +
      '&date=' + this.date +
      '&company=' + this.searchCompany +
      '&route=' + this.searchRoute +
      '&shift=' + this.searchShift +
      '&type=' + this.searchType +
      '&token=' + this.$store.state.accessToken)
        .then(response => {
          this.trips = response.data.data
          this.page = response.data.current_page
          this.pageCount = response.data.last_page
          this.from = response.data.from
          this.to = response.data.to
          this.total = response.data.total
        }).catch(err => {
          console.log(err)
        })
    },
    goTrip: function (trip) {
      this.$router.push({ name: 'viaje', params: { id: trip.id } })
    },
    fetchCompanies: function () {
      this.$axios.get(this.$store.state.url + 'api/companies?token=' + this.$store.state.accessToken)
        .then(response => {
          this.companies = []
          const data = response.data
          for (var i = 0; i < data.length; i++) {
            this.companies.push(
              { text: data[i].alias_name, value: data[i].id }
            )
          }
        }).catch(err => {
          console.log(err)
        })
    },
    fetchRoutes: function () {
      this.$axios.get(this.$store.state.url + 'api/routes?token=' + this.$store.state.accessToken)
        .then(response => {
          this.routes = []
          const data = response.data
          for (var i = 0; i < data.length; i++) {
            this.routes.push(
              { text: data[i].name, value: data[i].id }
            )
          }
        }).catch(err => {
          console.log(err)
        })
    },
    fetchShifts: function () {
      this.$axios.get(this.$store.state.url + 'api/shifts?token=' + this.$store.state.accessToken)
        .then(response => {
          this.shifts = []
          const data = response.data
          for (var i = 0; i < data.length; i++) {
            this.shifts.push(
              { text: data[i].name, value: data[i].id }
            )
          }
        }).catch(err => {
          console.log(err)
        })
    },
    clearFilter: function () {
      this.date = null
      this.search = ''
      this.searchCompany = 0
      this.searchRoute = 0
      this.searchShift = 0
      this.searchType = ''
    }
  },
  created () {
    this.fetchTrips(1)
    this.fetchCompanies()
    this.fetchRoutes()
    this.fetchShifts()
  },
  watch: {
    page (val) {
      this.fetchTrips(val)
    },
    searchCompany (val) {
      this.searchCompany = val
      this.fetchTrips(1)
    },
    searchRoute (val) {
      this.searchRoute = val
      this.fetchTrips(1)
    },
    searchShift (val) {
      this.searchShift = val
      this.fetchTrips(1)
    },
    searchType (val) {
      this.searchType = val
      this.fetchTrips(1)
    },
    date (val) {
      this.date = val
      this.fetchTrips(1)
    }
  },
  data () {
    return {
      date: null,
      menu: null,
      companies: [],
      routes: [],
      types: ['Entrada', 'Salida'],
      shifts: [],
      search: '',
      searchCompany: 0,
      searchRoute: 0,
      searchShift: 0,
      searchType: '',
      headers: [
        {
          text: 'Folio del viaje',
          align: 'start',
          sortable: false,
          value: 'folio'
        },
        { text: 'Ruta recorrida', value: 'name' },
        { text: 'Horario', value: 'schedule' },
        { text: 'Tipo', value: 'type' },
        { text: 'Fecha', value: 'created_at' }
      ],
      trips: [],
      headersTrip: [
        {
          text: 'NO de empleado',
          align: 'start',
          sortable: false,
          value: 'company_badge'
        },
        { text: 'Nombre', value: 'name' },
        { text: 'Paradero de arribo', value: 'stop' },
        { text: 'Hora de arribo', value: 'created_at' }
      ],
      page: 0,
      pageCount: 0,
      from: 0,
      to: 0,
      total: 0,
      tripBoards: []
    }
  }
}
</script>
