<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rows"
      class="elevation-1"
      style="cursor: pointer"
    >
      <template v-slot:item.created_at="{ item }">
        <span>{{ item.created_at | moment("DD/MM/YYYY LT")}}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="deleteRoute(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: 'group-routes',
  props: {
    groupId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Agregado', value: 'created_at' },
        { text: 'Acciones', value: 'actions', sortable: false }
      ],
      rows: []
    }
  },
  mounted () {
    this.fetchRoutes()
  },
  methods: {
    fetchRoutes: function () {
      this.$axios.get(`${this.$store.state.url}api/groups/${this.groupId}?token=${this.$store.state.accessToken}`)
        .then(response => {
          this.rows = response.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    deleteRoute: function (route) {
      this.$axios.delete(`${this.$store.state.url}api/groups/route/${route.id}?token=${this.$store.state.accessToken}`)
        .then(response => {
          this.fetchRoutes()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
