<template>
  <v-container fluid>
    <v-row
      justify="center"
    >
      <v-col style="padding: 30px;">
        <v-card style="padding: 20px;">
          <v-card-title>
            Traslados en progreso
            <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="trips"
            :search="search"
            class="elevation-1"
            @click:row="goTrip"
            style="cursor: pointer"
          >
          <template v-slot:item.folio="{ item }">
            <span style="font-weight: 600;">{{item.folio}}</span>
          </template>
          <template v-slot:item.created_at="{ item }">
            <span>{{ item.created_at | moment("DD/MM/YYYY LT")}}</span>
          </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  methods: {
    fetchTrips: function () {
      this.$axios.get(this.$store.state.url + 'api/tripsnow?token=' + this.$store.state.accessToken)
        .then(response => {
          this.trips = response.data
        }).catch(err => {
          console.log(err)
        })
    },
    goTrip: function (trip) {
      this.$router.push({ name: 'viaje', params: { id: trip.id } })
    },
    clearTrip: function () {
      this.tripBoards = []
    }
  },
  created () {
    this.fetchTrips()
  },
  data () {
    return {
      date: null,
      menu: null,
      search: '',
      headers: [
        {
          text: 'Folio del viaje',
          align: 'start',
          sortable: false,
          value: 'folio'
        },
        { text: 'Ruta recorrida', value: 'name' },
        { text: 'Horario', value: 'schedule' },
        { text: 'Tipo', value: 'type' },
        { text: 'Fecha', value: 'created_at' }
      ],
      trips: [],
      headersTrip: [
        {
          text: 'NO de empleado',
          align: 'start',
          sortable: false,
          value: 'company_badge'
        },
        { text: 'Nombre', value: 'name' },
        { text: 'Paradero de arribo', value: 'stop' },
        { text: 'Hora de arribo', value: 'created_at' }
      ],
      tripBoards: []
    }
  }
}
</script>
