<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col style="padding: 30px">
        <v-card style="padding: 20px">
          <v-card-title>
            <h1>Sobrecupos</h1>
          </v-card-title>
          <overcapacity-chart />
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col style="padding: 30px">
        <v-card style="padding: 20px">
          <v-card-title>
            <h1>Incidentes</h1>
          </v-card-title>
          <incidents-chart />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="admin" justify="center">
      <v-col cols="8" style="padding: 30px">
        <v-card style="padding: 20px">
          <v-card-title>
            <h3>Operadores Positivos: {{ positiveDrivers.length }}</h3>
          </v-card-title>
          <v-simple-table style="min-height:200px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Operador</th>
                  <th class="text-left">Ruta</th>
                  <th class="text-left">Horario</th>
                  <th class="text-left">Hora Positivo</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(driver, index) in positiveDrivers" :key="index">
                  <td>{{ driver.driver }}</td>
                  <td>{{ driver.name }}</td>
                  <td>{{ driver.schedule }}</td>
                  <td>{{  driver.created_at | moment("LT - DD/MM/YYYY") }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="4" style="padding: 30px">
        <v-card style="padding: 20px">
          <v-card-title>
            <h3>No Positivos: {{ drivers.length }}</h3>
          </v-card-title>
          <v-simple-table style="min-height:200px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Operador</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(driver, index) in drivers" :key="index">
                  <td>{{ driver.name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import IncidentsChart from '../components/IncidentsChart.vue'
import OvercapacityChart from '../components/OvercapacityChart.vue'

export default {
  components: { IncidentsChart, OvercapacityChart },
  name: 'Home',
  data () {
    return {
      drivers: [],
      positiveDrivers: [],
      admin: false,
      interval: null
    }
  },
  methods: {
    init: async function () {
      const roles = this.$store.state.roles
      this.admin = false
      await roles.forEach(async role => {
        if (role === 'admin') {
          this.admin = true
          this.fetchPositiveDrivers()
          this.intervalFetch()
        }
      })
    },
    fetchPositiveDrivers: function () {
      this.$axios.get(this.$store.state.url + 'api/reports/drivers/positive?token=' + this.$store.state.accessToken)
        .then(response => {
          this.positiveDrivers = response.data
          this.fetchDrivers()
        }).catch(err => {
          console.log(err)
        })
    },
    fetchDrivers: function () {
      this.$axios.get(this.$store.state.url + 'api/drivers?token=' + this.$store.state.accessToken)
        .then(response => {
          this.drivers = response.data
          this.setNoPositives()
        }).catch(err => {
          console.log(err)
        })
    },
    setNoPositives: function async () {
      this.drivers.forEach(driver => {
        this.positiveDrivers.forEach(positive => {
          if (driver.driverId === positive.id) {
            this.drivers.splice(this.drivers.indexOf(driver), 1)
          }
        })
      })
    },
    intervalFetch: function () {
      this.interval = setInterval(function () {
        this.fetchPositiveDrivers()
      }.bind(this), 20000)
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>
