<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col style="padding: 30px;">
        <v-card
          elevation="3"
        >
          <v-data-table
            :headers="headers"
            :items="buses"
            :search="search"
            sort-by="bus"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Unidades</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="dialog"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Nueva unidad
                      <v-icon
                        right
                        dark
                      >
                        mdi-plus-thick
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                          >
                            <v-text-field
                              v-model="editedItem.bus"
                              label="No. Económico"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                          >
                            <v-text-field
                              v-model="editedItem.type"
                              label="Tipo de unidad"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                          >
                            <v-text-field
                              v-model="editedItem.capacity"
                              label="Capacidad"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">¿Deseas eliminar esta unidad?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initialize"
              >
                Reset
              </v-btn>
            </template>
            <template v-slot:item.bus="{ item }">
              <span style="font-weight: 600;">{{item.bus}}</span>
            </template>
            <template v-slot:item.capacity="{ item }">
              <span>{{ item.capacity }} Personas</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="position: fixed; bottom: 1px; width:100%;">
      <v-col
        cols="12"
        sm="12"
        md="6">
        <v-alert
          border="bottom"
          elevation="4"
          type="success"
          :value="alert"

        >
          Guardado correctamente.
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    alert: false,
    search: '',
    headers: [
      {
        text: 'No Económico',
        align: 'start',
        sortable: false,
        value: 'bus'
      },
      { text: 'Tipo', value: 'type' },
      { text: 'Capacidad', value: 'capacity' },
      { text: 'Acciones', value: 'actions', sortable: false }
    ],
    buses: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      bus: '',
      type: '',
      capacity: 0
    },
    defaultItem: {
      id: 0,
      bus: '',
      type: '',
      capacity: 0
    }
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nueva Unidad' : 'Editar Unidad'
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize: function () {
      this.$axios.get(this.$store.state.url + 'api/buses?token=' + this.$store.state.accessToken)
        .then(response => {
          this.buses = response.data.data
        }).catch(err => {
          console.log(err)
        })
    },
    editItem (item) {
      this.editedIndex = this.buses.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.buses.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.delete(this.buses[this.editedIndex])
      this.buses.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save () {
      if (this.editedIndex > -1) {
        this.update(this.editedItem)
        Object.assign(this.buses[this.editedIndex], this.editedItem)
      } else {
        this.savePost(this.editedItem)
        this.buses.push(this.editedItem)
      }
      this.close()
    },
    savePost (item) {
      const data = {
        bus: item.bus,
        type: item.type,
        capacity: item.capacity
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/buses/save', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
          this.initialize()
        }).catch(err => {
          console.log(err)
        })
    },
    update (item) {
      const data = {
        id: item.id,
        bus: item.bus,
        type: item.type,
        capacity: item.capacity
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/buses/update', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
          this.initialize()
        }).catch(err => {
          console.log(err)
        })
    },
    delete (item) {
      const data = {
        id: item.id
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/buses/delete', data, config)
        .then(response => {
          this.alert = true
          this.alertCreated()
        }).catch(err => {
          console.log(err)
        })
    },
    alertCreated () {
      setTimeout(() => {
        this.alert = false
      }, 2000)
    },
    getColor (disbaledAt) {
      if (disbaledAt == null) return 'green'
      else return 'red'
    }
  }
}
</script>
