import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import JsonExcel from 'vue-json-excel'
import './registerServiceWorker'

/* Version 0.9.4 */
Vue.component('downloadExcel', JsonExcel)
Vue.prototype.$axios = axios
Vue.config.productionTip = false

Vue.use(require('vue-moment'))

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
