<template>
  <v-app id="inspire" style="background: transparent;">
    <layout v-if="$route.name !='login'"></layout>
    <login v-else></login>
  </v-app>
</template>

<script>
import layout from './views/Layout.vue'
import login from './views/Login.vue'
export default {
  components: {
    layout,
    login
  }
}
</script>
