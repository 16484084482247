<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
      >
        Agregar Ruta
        <v-icon
          right
          dark
        >
          mdi-plus-thick
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Agregar Ruta
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-select
              :items="routes"
              v-model="route"
              label="Elige la Ruta"
              dense
              outlined
            ></v-select>
          </v-col>
          </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="route"
            color="blue darken-1"
            text
            @click="save"
          >
            Guardar
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'group-dialog',
  props: {
    group: {
      default: {
        id: 0,
        name: '',
        company_id: 0
      }
    }
  },
  data () {
    return {
      routes: [],
      dialog: false,
      route: undefined
    }
  },
  mounted () {
    this.getRoutes()
  },
  methods: {
    getRoutes: function () {
      this.$axios.get(this.$store.state.url + 'api/routes?token=' + this.$store.state.accessToken)
        .then(response => {
          this.routes = response.data.map(item => {
            return { value: item.id, text: item.name }
          })
        }).catch(err => {
          console.log(err)
        })
    },
    save: function () {
      const data = {
        group: this.group.id,
        route: this.route
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/groups/route', data, config)
        .then(response => {
          this.$emit('change')
          this.close()
        }).catch(err => {
          console.log(err)
        })
    },
    close: function () {
      this.route = undefined
      this.dialog = false
    }
  }
}
</script>
