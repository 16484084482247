<template>
  <v-container fluid>
    <v-row
          justify="center"
        >
      <v-col style="padding: 30px;">
        <v-card style="padding: 20px;">
          <v-card-title>
            <h2 v-if="this.tripBoards.length > this.trip.capacity" style="margin-right: 30px">Sobrecupo</h2>
            <v-spacer></v-spacer>
            Unidad: {{ this.trip.bus }} | Capacidad: {{ this.trip.capacity }} | Ocupación: {{ Math.round(((100 / this.trip.capacity) * this.tripBoards.length) * 10 ) /10 }}%
          </v-card-title>
          <v-card-title>
            Ruta: {{ trip.route }}
            <v-spacer></v-spacer>
            Abordos: {{ this.tripBoards.length }}
            <v-spacer></v-spacer>
            <download-excel
              class   = "btn btn-success"
              :data   = "tripBoards"
              :fields = "excelBoards"
              worksheet = "Empleados"
              :name    = "excelBoardsName">
              <v-btn
                tile
                color="success"
              >
                <v-icon left>
                  mdi-table
                </v-icon>
                Descargar Excel
              </v-btn>
            </download-excel>
            <v-spacer></v-spacer>
            <v-btn
              tile
              color="primary"
              @click="goBack"
            >
              <v-icon left>
                mdi-arrow-left
              </v-icon>
              Regresar
            </v-btn>
            <v-spacer
              v-if="trip.id > 0 && !trip.finished_at"
            ></v-spacer>
            <v-btn
              v-if="trip.id > 0 && !trip.finished_at"
              tile
              color="warning"
              @click="closeTrip(trip)"
            >
              <v-icon left>
                mdi-close-circle-outline
              </v-icon>
              Finalizar
            </v-btn>
          </v-card-title>
              <v-card-title style="font-size: 1rem !important;">
                Folio: {{ trip.folio }}
                <v-spacer></v-spacer>
                Horario: {{ trip.shift }}
                <v-spacer></v-spacer>
                Chofer: {{ trip.driver }}
                <v-spacer></v-spacer>
                Tipo: {{ trip.type }}
                <v-spacer></v-spacer>
                Fecha: {{  trip.created_at | moment("DD/MM/YYYY LT") }}
              </v-card-title>
              <v-data-table
                :headers="headersBoards"
                :items="tripBoards"
                class="elevation-1"
              >
                <template v-slot:item.company_badge="{ item }">
                  <span style="font-weight: 600;">{{item.company_badge}}</span>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <span>{{ item.created_at | moment("hh:mm A")}}</span>
                </template>
              </v-data-table>
              <v-card-title style="margin-top: 20px;">
                Historial de Paradas |  {{ this.tripStops.length }}
                <v-spacer></v-spacer>
                <download-excel
                  class   = "btn btn-success"
                  :data   = "tripStops"
                  :fields = "excelStops"
                  worksheet = "Paraderos"
                  :name    = "excelStopsName">
                  <v-btn
                    tile
                    color="success"
                  >
                    <v-icon left>
                      mdi-table
                    </v-icon>
                    Descargar Excel
                  </v-btn>
                </download-excel>
              </v-card-title>
              <v-data-table
                :headers="headersStops"
                :items="tripStops"
                class="elevation-1"
              >
                <template v-slot:item.stop="{ item }">
                  <span style="font-weight: 600;">{{item.stop}}</span>
                </template>
                <template v-slot:item.arrival_at="{ item }">
                  <span>{{ item.created_at | moment("hh:mm A")}}</span>
                </template>
                <template v-slot:item.location="{ item }">
                  <a target="_blank" v-bind:href="'http://maps.google.com/maps?q=' + item.arrival_latitude + ',' + item.arrival_longitude + '&z=18'">
                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-bus-marker theme--light"></i>
                  </a>
                </template>
              </v-data-table>
              <!--  Incidents  -->
              <v-card-title style="margin-top: 20px;">
                Incidentes en el Viaje | {{ this.tripIncidents.length }}
                <v-spacer></v-spacer>
              </v-card-title>
              <v-data-table
                :headers="headersIncidents"
                :items="tripIncidents"
                class="elevation-1"
              >
                <template v-slot:item.badge="{ item }">
                  <span style="font-weight: 600;">{{item.badge}}</span>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <span>{{ item.created_at | moment("hh:mm A")}}</span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
  </v-container>
</template>
<script>
import Axios from 'axios'
import moment from 'moment'

export default {
  methods: {
    fetchTrip: async function () {
      const tripId = this.$route.params.id
      Axios.get(this.$store.state.url + 'api/trips/' + tripId + '?token=' + this.$store.state.accessToken)
        .then(response => {
          const data = response.data.data
          this.trip = data.trip
          this.tripBoards = data.boards
          this.tripStops = data.stops
          console.log(data.stops)
          this.excelBoardsName = moment(data.trip.created_at).format('YYYY-MM-DD') + '-' +
            data.trip.route + '-Empleados-' + this.trip.shift

          this.excelStopsName = moment(data.trip.created_at).format('YYYY-MM-DD') + '-' +
            data.trip.route + '-Paraderos-' + this.trip.shift
        }).catch(err => {
          console.log(err)
        })
    },
    fetchIncidents: function () {
      const tripId = this.$route.params.id
      Axios.get(this.$store.state.url + 'api/reports/incidents/' + tripId + '?token=' + this.$store.state.accessToken)
        .then(response => {
          this.tripIncidents = response.data
          console.log(this.tripIncidents)
        }).catch(err => {
          console.log(err)
        })
    },
    goBack: function () {
      this.$router.go(-1)
    },
    closeTrip: function (item) {
      const data = {
        trip_token: item.token
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$store.state.accessToken}`
        }
      }
      this.$axios.post(this.$store.state.url + 'api/trip/finish', data, config)
        .then(response => {
          this.fetchTrip()
        }).catch(err => {
          console.log(err)
        })
    }
  },
  created () {
    this.fetchTrip()
    this.fetchIncidents()
  },
  data () {
    return {
      trip: {
        id: 0,
        folio: '',
        route: '',
        shift: '',
        driver: '',
        type: '',
        bus: '',
        capacity: 0,
        created_at: ''
      },
      headersBoards: [
        {
          text: 'NO de empleado',
          align: 'start',
          sortable: false,
          value: 'company_badge'
        },
        { text: 'Nombre', value: 'name' },
        { text: 'Paradero de arribo', value: 'stop' },
        { text: 'Hora de arribo', value: 'created_at' }
      ],
      headersStops: [
        {
          text: 'Paradero',
          align: 'start',
          sortable: false,
          value: 'stop'
        },
        { text: 'Referencia', value: 'reference' },
        { text: 'Hora de arribo', value: 'arrival_at' },
        { text: 'Ubicación', value: 'location' }
      ],
      headersIncidents: [
        {
          text: 'Empleado',
          align: 'start',
          sortable: false,
          value: 'badge'
        },
        { text: 'Nombre', value: 'name' },
        { text: 'Grupo Asignado', value: 'group' },
        { text: 'Horario', value: 'shift' },
        { text: 'Tipo', value: 'type' },
        { text: 'Descripción', value: 'description' },
        { text: 'Hora de abordo', value: 'created_at' }
      ],
      tripBoards: [],
      tripStops: [],
      tripIncidents: [],
      excelBoards: {
        'NO de empleado': 'company_badge',
        Empleado: 'name',
        Paradero: 'stop',
        'Hora de arribo': {
          field: 'created_at',
          callback: (value) => {
            return moment(value).format('hh:mm A')
          }
        }
      },
      excelStops: {
        Paradero: 'stop',
        Referencia: 'reference',
        'Hora de arribo': {
          field: 'arrival_at',
          callback: (value) => {
            return moment(value).format('hh:mm A')
          }
        },
        Latitud: 'arrival_latitude',
        Longitud: 'arrival_longitude'
      },
      excelBoardsName: 'name.xls',
      excelStopsName: 'name.xls'
    }
  }
}
</script>
