<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      style="padding-top: 30px;"
      v-if="$route.name !='login'"
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row
            v-if="item.heading"
            :key="item.heading"
            align="center"
          >
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col
              cols="6"
              class="text-center"
            >
              <a
                href="#!"
                class="body-2 black--text"
              >EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="item.text"
            :to="item.link"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-btn
        rounded
        class="ml-3"
        color="primary"
        dark
        large
        @click="logout"
        width="80%"
      >
        Salir
        <v-icon
          right
          dark
        >
          mdi-logout
        </v-icon>
      </v-btn>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#304ffe"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-5"
      >
        <v-img
          lazy-src="@/assets/logo2.png"
          max-height="120"
          max-width="80"
          src="@/assets/logo2.png"
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <keep-alive include="viajes, sobrecupo">
        <router-view :key="$route.fullPath"></router-view>
      </keep-alive>
    </v-main>

    <v-dialog
      v-model="dialog"
      width="800px"
    >
      <v-card>
        <v-card-title class="grey darken-2">
          Create contact
        </v-card-title>
        <v-container>
          <v-row class="mx-2">
            <v-col
              class="align-center justify-space-between"
              cols="12"
            >
              <v-row
                align="center"
                class="mr-0"
              >
                <v-avatar
                  size="40px"
                  class="mx-3"
                >
                  <img
                    src="//ssl.gstatic.com/s2/oz/images/sge/grey_silhouette.png"
                    alt=""
                  >
                </v-avatar>
                <v-text-field
                  placeholder="Name"
                ></v-text-field>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-text-field
                prepend-icon="mdi-account-card-details-outline"
                placeholder="Company"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                placeholder="Job title"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-mail"
                placeholder="Email"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="tel"
                prepend-icon="mdi-phone"
                placeholder="(000) 000 - 0000"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-text"
                placeholder="Notes"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn
            text
            color="primary"
          >More</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="dialog = false"
          >Cancel</v-btn>
          <v-btn
            text
            @click="dialog = false"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    source: String
  },
  data: () => ({
    dialog: false,
    drawer: null,
    items: [
      { icon: 'mdi-home', text: 'Inicio', link: '/inicio' },
      { icon: 'mdi-clock-outline', text: 'En transcurso', link: '/tiempo-real' },
      { icon: 'mdi-bus-marker', text: 'Traslados', link: '/viajes' },
      { icon: 'mdi-account', text: 'Reporte Empleado', link: '/reporte/empleados' }
    ]
  }),
  created () {
    this.roleValidation()
  },
  methods: {
    logout () {
      this.$store.commit('clearToken')
      this.$router.push({ name: 'login' })
    },
    async roleValidation () {
      const roles = this.$store.state.roles
      const items = [
        { icon: 'mdi-bus-alert', text: 'Sobrecupos', link: '/reporte/sobrecupo', roles: ['admin'] },
        { icon: 'mdi-account-remove', text: 'Incidentes', link: '/reporte/incidentes', roles: ['admin'] },
        { icon: 'mdi-domain', text: 'Empresas', link: '/empresas', roles: ['admin'] },
        { icon: 'mdi-bus-clock', text: 'Rutas', link: '/rutas', roles: ['admin'] },
        { icon: 'mdi-bus-multiple', text: 'Grupos', link: '/grupos', roles: ['admin'] },
        { icon: 'mdi-account-tie', text: 'Choferes', link: '/choferes', roles: ['admin'] },
        { icon: 'mdi-bus-stop-covered', text: 'Paradas', link: '/paradas', roles: ['admin'] },
        { icon: 'mdi-bus', text: 'Unidades', link: '/unidades', roles: ['admin'] },
        { icon: 'mdi-account-group', text: 'Empleados', link: '/empleados', roles: ['admin', 'company'] },
        { icon: 'mdi-account', text: 'Usuarios', link: '/usuarios', roles: ['admin'] }
      ]
      items.forEach(item => {
        if (roles.some(role => item.roles.includes(role))) {
          this.items.push(item)
        }
      })
    }
  }
}
</script>
