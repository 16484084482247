<template>
  <div>
    <v-container fluid>
      <v-row
            justify="center"
          >
        <v-col style="padding: 30px;">
          <v-card style="padding: 20px;">
            <v-card-title>
              <h3>Reporte de sobrecupos</h3>
              <v-divider
                    class="mx-4"
                    inset
                    vertical
              >
              </v-divider>
              <h3>{{ total }}</h3>
            </v-card-title>
            <v-card-title>
              <v-container>
                <v-row>
                  <v-col
                    class="d-flex"
                    cols="3"
                    sm="3"
                  >
                    <v-select
                      :items="companies"
                      v-model="model.company"
                      label="Empresa"
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="3"
                    sm="3"
                  >
                    <v-select
                      :items="routes"
                      v-model="model.route"
                      label="Ruta"
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="3"
                    sm="3"
                  >
                    <v-select
                      :items="shifts"
                      v-model="model.shift"
                      label="Horario"
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="3"
                    sm="3"
                  >
                    <v-btn
                      dark
                      class="warning"
                      @click="clearFilters"
                    >
                      Limpiar
                      <v-icon
                        right
                        dark
                      >
                        mdi-eraser
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="model.folio"
                      @keyup.enter="fetchReport(1)"
                      append-icon="mdi-magnify"
                      label="Folio del Viaje"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="model.bus"
                      @keyup.enter="fetchReport(1)"
                      append-icon="mdi-magnify"
                      label="No de Unidad"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="3"
                    sm="3"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="model.date"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="model.date"
                          label="Filtrar por fecha"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          style="padding-top: 0px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="model.date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(model.date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
              <v-data-table
                :headers="headers"
                :items="rows"
                :items-per-page="25"
                @click:row="goTrip"
                class="elevation-1"
                style="cursor: pointer"
                hide-default-footer
              >
                <template v-slot:item.folio="{ item }">
                  <span style="font-weight: 600;">{{item.folio}}</span>
                </template>
                <template v-slot:item.sobrecupo="{ item }">
                  <span>{{ item.boards - item.capacity }}</span>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <span>{{ item.created_at | moment("DD/MM/YYYY LT")}}</span>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                ></v-pagination>
              </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'sobrecupo',
  data () {
    return {
      overlay: false,
      rows: [],
      headers: [
        {
          text: 'Folio',
          align: 'start',
          sortable: false,
          value: 'folio'
        },
        { text: 'Ruta', value: 'name' },
        { text: 'Horario', value: 'shift' },
        { text: 'Tipo', value: 'way' },
        { text: 'Unidad', value: 'bus' },
        { text: 'Capacidad', value: 'capacity' },
        { text: 'Ocupación', value: 'boards' },
        { text: 'Sobrecupo', value: 'sobrecupo' },
        { text: 'Fecha', value: 'created_at' }
      ],
      page: 0,
      pageCount: 0,
      from: 0,
      to: 0,
      total: 0,
      companies: [],
      routes: [],
      shifts: [],
      model: {
        folio: '',
        route: 0,
        company: 0,
        bus: '',
        shift: 0,
        date: ''
      },
      menu: null
    }
  },
  mounted () {
    this.fetchReport(1)
    this.fetchCompanies()
    this.fetchRoutes()
    this.fetchShifts()
  },
  watch: {
    page (val) {
      this.fetchReport(val)
    },
    'model.route' (val) {
      this.fetchReport(1)
    },
    'model.company' (val) {
      this.fetchReport(1)
    },
    'model.shift' (val) {
      this.fetchReport(1)
    },
    'model.date' (val) {
      this.fetchReport(1)
    }
  },
  methods: {
    fetchReport: function (page) {
      this.overlay = true
      this.$axios.get(`${this.$store.state.url}api/reports/overcrowded?page=${page}
        &folio=${this.model.folio}
        &route=${this.model.route}
        &company=${this.model.company}
        &bus=${this.model.bus}
        &shift=${this.model.shift}
        &date=${this.model.date}
        &token=${this.$store.state.accessToken}`)
        .then(response => {
          this.rows = response.data.data
          this.pageCount = response.data.last_page
          this.page = response.data.current_page
          this.total = response.data.total
          this.overlay = false
        }).catch(err => {
          console.log(err)
        })
    },
    fetchCompanies: function (page) {
      this.$axios.get(`${this.$store.state.url}api/companies?token=${this.$store.state.accessToken}`)
        .then(response => {
          this.companies = []
          const data = response.data
          for (var i = 0; i < data.length; i++) {
            this.companies.push(
              { text: data[i].alias_name, value: data[i].id }
            )
          }
        }).catch(err => {
          console.log(err)
        })
    },
    fetchRoutes: function () {
      this.$axios.get(this.$store.state.url + 'api/routes?token=' + this.$store.state.accessToken)
        .then(response => {
          this.routes = []
          const data = response.data
          for (var i = 0; i < data.length; i++) {
            this.routes.push(
              { text: data[i].name, value: data[i].id }
            )
          }
        }).catch(err => {
          console.log(err)
        })
    },
    fetchShifts: function () {
      this.$axios.get(this.$store.state.url + 'api/shifts?token=' + this.$store.state.accessToken)
        .then(response => {
          this.shifts = []
          const data = response.data
          for (var i = 0; i < data.length; i++) {
            this.shifts.push(
              { text: data[i].name, value: data[i].id }
            )
          }
        }).catch(err => {
          console.log(err)
        })
    },
    clearFilters: function () {
      this.model = {
        folio: '',
        route: 0,
        company: 0,
        bus: '',
        shift: 0,
        date: ''
      }
      this.fetchReport(1)
    },
    goTrip: function (trip) {
      this.$router.push({ name: 'viaje', params: { id: trip.trip_id } })
    }
  }
}
</script>
