<template>
  <v-container fluid>
    <v-row>
      <v-col style="padding: 30px" cols="12" sm="12" md="6">
        <v-card
          elevation="3"
          style="padding-left: 20px; padding-right: 20px; padding-top: 10px"
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                label="Ruta"
                v-model="route.name"
                style="padding-top: 0px"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="companies"
                v-model="route.company_id"
                label="Empresa"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-btn color="primary" dark class="mb-2" @click="saveRoute">
                Guardar
                <v-icon right dark> mdi-content-save </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col style="padding: 30px" cols="12" sm="12" md="6">
        <v-card elevation="3">
          <v-data-table
            :headers="shiftHeaders"
            :items="shifts"
            sort-by="name"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Horarios</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialogShift" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Nuevo Horario
                      <v-icon right dark> mdi-plus-thick </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formShiftTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-select
                              :items="shiftsList"
                              v-model="editedShift.shift_id"
                              label="Horario"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedShift.start_at_label"
                              label="Hora de inicio"
                              style="padding-top: 0px"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedShift.finish_at_label"
                              label="Hora de finalización"
                              style="padding-top: 0px"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeShift">
                        Cancelar
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="saveShift">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogShiftDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline"
                      >¿Deseas eliminar esta Horario?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeShiftDelete"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteShiftConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editShift(item)">
                mdi-pencil
              </v-icon>
              <v-icon small class="mr-2" @click="selectShift(item)">
                mdi-bus-marker
              </v-icon>
              <v-icon small @click="deleteShift(item)"> mdi-delete </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Reset </v-btn>
            </template>
            <template v-slot:item.shift="{ item }">
              <span style="font-weight: 600">{{ item.shift }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="selectedShift != null">
      <v-col style="padding: 30px" cols="12" sm="12" md="12">
        <v-card elevation="3">
          <v-data-table
            :headers="stopHeaders"
            :items="stops"
            :items-per-page="50"
            sort-by="name"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Paradas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                {{ selectedShift.shift }}
                <v-spacer></v-spacer>
                <v-file-input
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  label="Cargar Excel"
                  id="excelFile"
                  class="mt-4"
                ></v-file-input>
                <v-btn
                  color="success"
                  dark
                  class="ml-2"
                  v-on:click="excelLoader"
                >
                  <v-icon left> mdi-table </v-icon>
                  Guardar
                </v-btn>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialogStop" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Nueva Parada
                      <v-icon right dark> mdi-plus-thick </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formStopTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedStop.location"
                              label="Ubicación"
                              style="padding-top: 0px"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedStop.reference"
                              label="Referencia"
                              style="padding-top: 0px"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedStop.mustBeAtLabel"
                              label="Hora de inicio"
                              style="padding-top: 0px"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeStop">
                        Cancelar
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="saveStop">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogStopDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline"
                      >¿Deseas eliminar esta Horario?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeStopDelete"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteStopConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.ordering="{ item }">
              <v-icon @click="orderUp(item)"> mdi-chevron-up </v-icon>
              <v-icon @click="orderDown(item)"> mdi-chevron-down </v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="deleteStop(item)"> mdi-delete </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Reset </v-btn>
            </template>
            <template v-slot:item.shift="{ item }">
              <span style="font-weight: 600">{{ item.shift }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="position: fixed; bottom: 1px; width: 100%">
      <v-col cols="12" sm="12" md="6">
        <v-alert border="bottom" elevation="4" :type="alert.color" :value="alert.visible">
          {{ alert.message }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import xlsx from "read-excel-file";
export default {
  data: () => ({
    dialogShift: false,
    dialogStop: false,
    dialogShiftDelete: false,
    dialogStopDelete: false,
    alert: {
      visible: false,
      color: 'success',
      message: ''
    },
    modelExel: {
      file: null,
    },
    route: {
      id: 0,
      name: "",
      company_id: 0,
    },
    companies: [],
    drivers: [],
    shiftHeaders: [
      {
        text: "Horario",
        align: "start",
        sortable: false,
        value: "shift",
      },
      { text: "Hora de inicio", value: "start_at_label" },
      { text: "Hora de finalización", value: "finish_at_label" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    shifts: [],
    selectedShift: null,
    shiftsList: [],
    stopHeaders: [
      { text: "Ordenar", value: "ordering", sortable: false },
      { text: "No.", value: "sequence" },
      {
        text: "Ubicación",
        align: "start",
        sortable: false,
        value: "location",
      },
      {
        text: "Referencia",
        align: "start",
        sortable: false,
        value: "reference",
      },
      { text: "Hora de llegada", value: "must_be_at_label" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    stops: [],
    editedShiftIndex: -1,
    editedShift: {
      id: 0,
      route_id: 0,
      shift_id: 0,
      start_at: 0,
      start_at_label: "",
      finish_at: 0,
      finish_at_label: "",
    },
    defaultShiftItem: {
      id: 0,
      route_id: 0,
      shift_id: 0,
      start_at: 0,
      start_at_label: "",
      finish_at: 0,
      finish_at_label: "",
    },
    editedStopIndex: -1,
    editedStop: {
      id: 0,
      route_shift_id: 0,
      location: "",
      reference: "",
      mustBeAtLabel: "",
      sequence: 0,
    },
    defaultStopItem: {
      id: 0,
      route_shift_id: 0,
      stop_id: 0,
      mustBeAtLabel: "",
      sequence: 0,
    },
    searchStop: "",
    selectedStop: {},
    stopsList: [],
  }),

  computed: {
    formShiftTitle() {
      return this.editedShiftIndex === -1 ? "Nuevo Horario" : "Editar Horario";
    },
    formStopTitle() {
      return this.editedStopIndex === -1 ? "Nueva Parada" : "Editar Parada";
    },
  },
  watch: {
    dialogShift(val) {
      val || this.closeShift();
    },
    dialogShiftDelete(val) {
      val || this.closeShiftDelete();
    },
    selectedShift(val) {
      val || this.selectShift(val);
      this.getStops();
    },
  },

  created() {
    this.initialize();
    this.getRouteInfo();
    this.getCompanies();
    this.getShiftsList();
    this.searchStops();
  },

  methods: {
    initialize: function () {
      const routeId = this.$route.params.id;
      this.$axios
        .get(
          this.$store.state.url +
            "api/shifts/" +
            routeId +
            "?token=" +
            this.$store.state.accessToken
        )
        .then((response) => {
          this.shifts = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchStops: function () {
      this.$axios
        .get(
          this.$store.state.url +
            "api/stops?search=" +
            this.searchStop +
            "&page=" +
            1 +
            "&token=" +
            this.$store.state.accessToken
        )
        .then((response) => {
          this.stopsList = [];
          const data = response.data.data;
          for (var i = 0; i < data.length; i++) {
            this.stopsList.push({ text: data[i].location, value: data[i].id });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRouteInfo: function () {
      const routeId = this.$route.params.id;
      this.$axios
        .get(
          this.$store.state.url +
            "api/routes/" +
            routeId +
            "?token=" +
            this.$store.state.accessToken
        )
        .then((response) => {
          this.route = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getShiftsList: function () {
      this.$axios
        .get(
          this.$store.state.url +
            "api/shifts?token=" +
            this.$store.state.accessToken
        )
        .then((response) => {
          const data = response.data;
          for (var i = 0; i < data.length; i++) {
            this.shiftsList.push({ text: data[i].name, value: data[i].id });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStops: function () {
      this.$axios
        .get(
          this.$store.state.url +
            "api/routestops/" +
            this.selectedShift.id +
            "?token=" +
            this.$store.state.accessToken
        )
        .then((response) => {
          this.stops = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompanies: function () {
      this.$axios
        .get(
          this.$store.state.url +
            "api/companies?token=" +
            this.$store.state.accessToken
        )
        .then((response) => {
          const data = response.data;
          for (var i = 0; i < data.length; i++) {
            this.companies.push({
              text: data[i].alias_name,
              value: data[i].id,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveRoute() {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.$store.state.accessToken}`,
        },
      };
      this.$axios
        .post(this.$store.state.url + "api/routes/update", this.route, config)
        .then((response) => {
          this.alert.visible = true
          this.alert.color = 'success'
          this.alert.message = 'Guardado correctamente.'
          this.alertCreated()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editShift(item) {
      this.editedShiftIndex = this.shifts.indexOf(item);
      this.editedShift = Object.assign({}, item);
      this.dialogShift = true;
    },
    selectShift(item) {
      this.selectedShift = item;
    },
    editStop(item) {
      this.editedStopIndex = this.stops.indexOf(item);
      this.editedStop = Object.assign({}, item);
      this.dialogStop = true;
    },

    deleteShift(item) {
      this.editedShiftIndex = this.shifts.indexOf(item);
      this.editedShift = Object.assign({}, item);
      this.dialogShiftDelete = true;
    },

    deleteShiftConfirm() {
      this.deleteShiftPost(this.shifts[this.editedShiftIndex]);
      this.shifts.splice(this.editedShiftIndex, 1);
      this.closeShiftDelete();
    },

    closeShift() {
      this.dialogShift = false;
      this.$nextTick(() => {
        this.editedShift = Object.assign({}, this.defaultShiftItem);
        this.editedShiftIndex = -1;
      });
    },

    closeShiftDelete() {
      this.dialogShiftDelete = false;
      this.$nextTick(() => {
        this.editedShift = Object.assign({}, this.defaultShiftItem);
        this.editedShiftIndex = -1;
      });
    },
    deleteStop(item) {
      this.editedStopIndex = this.stops.indexOf(item);
      this.editedStop = Object.assign({}, item);
      this.dialogStopDelete = true;
    },

    deleteStopConfirm() {
      this.deleteStopPost(this.stops[this.editedStopIndex]);
      this.stops.splice(this.editedStopIndex, 1);
      this.closeStopDelete();
    },

    closeStop() {
      this.dialogStop = false;
      this.$nextTick(() => {
        this.editedStop = Object.assign({}, this.defaultStopItem);
        this.editedStopIndex = -1;
      });
    },

    closeStopDelete() {
      this.dialogStopDelete = false;
      this.$nextTick(() => {
        this.editedStop = Object.assign({}, this.defaultStopItem);
        this.editedStopIndex = -1;
      });
    },
    saveShift() {
      if (this.editedShiftIndex > -1) {
        this.update(this.editedShift);
        Object.assign(this.shifts[this.editedShiftIndex], this.editedShift);
      } else {
        this.saveShiftPost(this.editedShift);
        this.shifts.push(this.editedShift);
      }
      this.closeShift();
    },
    saveShiftPost(item) {
      const data = {
        routeId: this.route.id,
        shiftId: item.shift_id,
        startLabel: item.start_at_label,
        finishLabel: item.finish_at_label,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.$store.state.accessToken}`,
        },
      };
      this.$axios
        .post(this.$store.state.url + "api/shifts/save", data, config)
        .then((response) => {
          this.alert.visible = true
          this.alert.color = 'success'
          this.alert.message = 'Guardado correctamente.'
          this.alertCreated();
          this.initialize();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveStop() {
      if (this.editedStopIndex > -1) {
        this.update(this.editedStop);
        Object.assign(this.stops[this.editedStopIndex], this.editedStop);
      } else {
        this.saveStopPost(this.editedStop);
        this.stops.push(this.editedStop);
      }
      this.closeStop();
    },
    saveStopPost(item) {
      const data = {
        routeShiftId: this.selectedShift.id,
        location: item.location,
        reference: item.reference,
        mustBeAtLabel: item.mustBeAtLabel,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.$store.state.accessToken}`,
        },
      };
      this.$axios
        .post(this.$store.state.url + "api/routestops/save", data, config)
        .then((response) => {
         this.alert.visible = true
          this.alert.color = 'success'
          this.alert.message = 'Guardado correctamente.'
          this.alertCreated();
          this.getStops();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    update(item) {
      const data = {
        id: item.id,
        shiftId: item.shift_id,
        startLabel: item.start_at_label,
        finishLabel: item.finish_at_label,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.$store.state.accessToken}`,
        },
      };
      this.$axios
        .post(this.$store.state.url + "api/shifts/update", data, config)
        .then((response) => {
          this.alert.visible = true
          this.alert.color = 'success'
          this.alert.message = 'Guardado correctamente.'
          this.alertCreated();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteShiftPost(item) {
      const data = {
        id: item.id,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.$store.state.accessToken}`,
        },
      };
      this.$axios
        .post(this.$store.state.url + "api/shifts/delete", data, config)
        .then((response) => {
          this.alert.visible = true
          this.alert.color = 'success'
          this.alert.message = 'Guardado correctamente.'
          this.alertCreated();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteStopPost(item) {
      const data = {
        id: item.id,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.$store.state.accessToken}`,
        },
      };
      this.$axios
        .post(this.$store.state.url + "api/routestops/delete", data, config)
        .then((response) => {
          this.alert.visible = true
          this.alert.color = 'success'
          this.alert.message = 'Guardado correctamente.'
          this.alertCreated();
          this.getStops();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    orderUp(item) {
      let index = this.stops.indexOf(item);
      if (index > 0) {
        index--;
        Object.assign(
          this.stops.splice(index, 2, this.stops[index + 1], this.stops[index])
        );
        this.postOrder();
      }
    },
    orderDown(item) {
      const index = this.stops.indexOf(item);
      if (index < this.stops.length - 1) {
        Object.assign(
          this.stops.splice(index, 2, this.stops[index + 1], this.stops[index])
        );
        this.postOrder();
      }
    },
    postOrder() {
      const data = {
        items: this.stops,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.$store.state.accessToken}`,
        },
      };
      this.$axios
        .post(this.$store.state.url + "api/routestops/order", data, config)
        .then((response) => {
          this.alert.visible = true
          this.alert.color = 'success'
          this.alert.message = 'Guardado correctamente.'
          this.alertCreated();
          this.getStops();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    alertCreated() {
      setTimeout(() => {
        this.alert.visible = false;
      }, 2000);
    },
    goRoute(route) {
      this.$router.push({ name: "ruta", params: { id: route.id } });
    },
    excelLoader: async function () {
      const input = document.getElementById("excelFile");
      var loadedStops = [];
      if (input.files.length > 0) {
        await xlsx(input.files[0]).then(async (rows) => {
          await rows.forEach((row, i) => {
            if (i > 0) {
              const stop = {
                location: row[0],
                reference: row[1] ?? "NA",
                mustBeAtLabel: row[2] ?? "NA",
              };
              loadedStops.push(stop);
            }
          });

          const data = {
            routeShiftId: this.selectedShift.id,
            stops: loadedStops
          };
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.state.accessToken}`,
            },
          };
          this.$axios
            .post(this.$store.state.url + "api/routestops/savemany", data, config)
            .then((response) => {
              this.alert.visible = true
              this.alert.color = 'success'
              this.alert.message = 'Guardado correctamente.'
              this.alertCreated();
              this.getStops();
            })
            .catch((err) => {
              this.alert.visible = true
              this.alert.color = 'warning'
              this.alert.message = 'Error al guardar la información.'
              this.alertCreated();
            });
        });
      }
    },
  },
};
</script>
