<template>
  <v-container fluid>
    <v-row
      justify="center"
    >
      <v-col
      class="text-center"
      cols="12"
      sm="12"
      md="12"
      style="padding: 30px;">
        <h1 style="font-size: 10rem;">404</h1>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
</style>
